import styles from './OnePlace.module.css'

import laptop from '../../assets/img/laptop-cut.svg'

export default function OnePlace() {
  return(
    <div className={styles.onePlace__container}>
      <img src={laptop} className={styles.img}/>
      <div className={styles.onePlace_info}>
        <h2 className={styles.title}>Все, что Вам нужно - в одном месте</h2>
        <ul className={styles.list_marker}>
            <li className={styles.list_item}>Дата и время</li>
            <li className={styles.list_item}>Пожелания</li>
            <li className={styles.list_item}>Анкета гостя</li>
            <li className={styles.list_item}>Программа дня</li>
            <li className={styles.list_item}>Дресс - код</li>
            <li className={styles.list_item}>Отсчет до мероприятия</li>
            <li className={styles.list_item}>Именная ссылка с доступом на целый год</li>
          </ul>
      </div>
    </div>
  )
}