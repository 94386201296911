import styles from './OrderDetails.module.css'

export default function OrderDetails() {
  return(
    <div className={styles.details__container}>
      <h2 className={styles.title}>Вы всегда можете заказать</h2>
      <h3 className={styles.subtitle}>персональный макет</h3>

      <div className={styles.info_maket}>
        <ul className={styles.list_marker}>
          <li className={styles.list_item}>Срок изготовления: 8-14 дней</li>
          <li className={styles.list_item}>Индивидуальный дизайн</li>
          <li className={styles.list_item}>Индивидуальный дизайн</li>
        </ul>
        <button className={`${styles.btn} ${styles.btn_price_maket}`}>Стоимость<br /> изготовления<br /> от 8500 р</button>
      </div>

      <div className={styles.additionally}>
        <h2 className={styles.title}>Дополнительно</h2>
        <ul className={styles.list_marker}>
          <li className={styles.list_item}>
            <span>Срочное изготовление макета в течении 1 дня</span>
            <button className={`${styles.btn} ${styles.btn_price}`}>1000 р</button>
          </li>
          <li className={styles.list_item}>
            Именные пригласительные для каждого гостя
            <button className={`${styles.btn} ${styles.btn_price}`}>1000 р</button>
          </li>
          <li className={styles.list_item}>
            Версия макета для печати пригласительного
            <button className={`${styles.btn} ${styles.btn_price}`}>от 100 р</button>
          </li>
        </ul>
      </div>
    </div>
  )
}