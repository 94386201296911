import TextField from '../TextField/View/TextField'
import styles from './Order.module.css'
import { useState, useEffect } from "react"
import SendButton from './sendFormButton'

export default function Order() {
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const onKeyDown = (e) => {
    var charCode = (e.which) ? e.which : e.keyCode

    if (charCode == 13) {
      document.querySelector('.order__name-input').blur()
      document.querySelector('.order__phone-input').focus()
    }
  }

  const getData = () => {
    const fields = document.querySelectorAll('.input');
    if (fields == null || fields === undefined) { 
        console.error(`Ошибка формы: поле fields не указано`)
        return null 
    }
    let object = {
      name: {
        value: name
      },
      phone: {
        value: phone
      }
    }
    return object
  }

  let initData = getData()
  const [data, setData] = useState(initData)
  
  const onChange = (e) => {
    setName(e.target.value)
    setData(getData())
  }

  const onChangePhone = (e) => {
    setPhone(e)
  }

  useEffect(() => {
    setData(getData())
  }, [phone])

  return(
    <div className={styles.order__container} id='order'>
      <h2 className={styles.title}>Для заказа пригласительного</h2>
      <h3 className={styles.subtitle}>Оставьте заявку <br/>и мы свяжемся с вами</h3>

      <div className={styles.actions} >
        <div className={styles.textfields}>
          <div className={styles.inputs}>
            <form>
              <input className={`${styles.input_name} order__name-input input`} type='text' placeholder='Ваше Имя' onChange={onChange} onKeyDown={onKeyDown} required />
              <TextField className={`${styles.phone} input`} onChange={ e => { onChangePhone(e) }} />
            </form>
          </div>
          </div>

          <div className={styles.social}>
            <p className={styles.text}>
              или напишите нам <br/>в один из мессенджеров
            </p>
            <div className={styles.buttons}>
              <button className={styles.btn_wa}>
                <a href="https://wa.me/79046526326" target="_blank" className={styles.link}>WhatsApp</a>
              </button>
              <button className={styles.btn_tel}>
                <a href="https://t.me/YG_force1" target="_blank" className={styles.link}>Telegram</a>
              </button>
              <SendButton 
                data={data}
                spreadsheet='1Ua_gXH9aG_-38ACbZNWvv0ZAQmryMBNDN3h45O27z4c'
                // onClick={onSend}
                // onClick={console.log(data)}
              />
            </div>
          </div>
        </div>
      </div>
  )
}