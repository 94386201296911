import styles from './Reviews.module.css'

import insta from './assets/inst.svg'
import avito from './assets/avito.svg'
import star from './assets/star.svg'
import branch from '../../assets/img/branch4.png'
import { ReviewCell } from './Cell'

export default function Reviews() {
  return(
    <div className={styles.reviews__container} id='reviews'>
      <div className={styles.reviews_text}>
        <h2 className={styles.title}>Отзывы</h2>
        <h3 className={styles.subtitle}>наших счастливых молодоженов</h3>
        <p className={styles.text}>
          Вы можете узнать мнение наших заказчиков о нас на разных площадках
        </p>
      </div>
      <div className={`${styles.review_block} ${styles.inst}`}>
        <img className={styles.inst_img} src={insta} width='.' height='.' /> <br />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <p className={styles.name}>Арина</p>
        <p className={styles.review_text}>
          Приглашения получились просто отпад, гости в восторге, 
          Александр учел все пожелания и получилось так как я хотела )) 
          в кратчайшие сроки )) Спасибо за работу )) рекомендую))
        </p>
      </div>
      <div className={`${styles.review_block} ${styles.avito1}`}>
        <img className={styles.avito_img} src={avito} width='.' height='.' /> <br />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <p className={styles.name}>Надежда</p>
        <p className={styles.review_text}>
          Огромная благодарность Александру за созданный сайт-приглашение на свадьбу! ❤
          При составлении учитывает все пожелания, даёт рекомендации по оформлению. Очень порадовало, 
          что остаётся на связи даже после выполнения заказа в случае внесения корректировок. 
          Профессиональный и отзывчивый специалист. Очень рекомендую 👏🏻👍🏻
        </p>
      </div>
      <div className={`${styles.review_block} ${styles.avito2}`}>
        <img className={styles.avito_img} src={avito} width='.' height='.' /> <br />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <img className={styles.star_img} src={star} width='.' height='.' />
        <p className={styles.name}>Алёна</p>
        <p className={styles.review_text}>
        Всё супер! Нужно было срочно, Александр сделал меньше, чем за сутки. 
        Всё понравилось, мы в восторге, спасибо большое!
        </p>
      </div>

      <p className={styles.inst_reviws}>Еще больше отзывов в нашем  
        <a href="https://www.instagram.com/_sv_today/" target="_blank" className={styles.link_inst}> Instagram*</a>
      </p>

      {/* <img className={styles.branch} src={branch} /> */}
    </div>
  )
}