import styles from './Navbar.module.css'
import { useState, useEffect } from "react"
import { NavbarViewController } from './NavbarViewController.ts'

export default function Navbar() {
  const [isMounted, setMounted] = useState(false)
  const [controller, _] = useState(NavbarViewController.shared)

  useEffect(() => {
    controller.configureNavBar()
    setMounted(true)
  }, [])

  return(
    <div className={styles.navbar__container}>
    { isMounted &&
      controller
      .getNavBarLinks()
      .map(item => {
          return (
            <button 
            id={`${item.tag}-button`} 
            className={styles.link} 
            onClick={el => { controller.scrollTo(item)}}>
              {item.title}
            </button>
            )
        }
      )
    }
    </div>
  )
}