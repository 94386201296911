import React, { useState } from "react"
import GoogleApi from "../TextField/Controller/GoogleApi"
import "./send-button.css"
import { clear } from "@testing-library/user-event/dist/clear.js"
// import { setModalVisible, setModalInvisible } from "../components/modal.js"

const SendButton = (props) => {
    let data = props.data
    const onError = (props.onError !== undefined) ? props.onError : (el) => {}
    const onClick = (props.onClick !== undefined) ? props.onClick : () => {}
    const url = props.url
    const spreadsheet = props.spreadsheet
    const [dataIsLoading, setLoading] = useState(false)

    const sendData = async (e) => {
        let hasError = false

        let keys = Object.keys(data)
        let row = []
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            console.log(data[key].value)
            if (data[key].value.length == 0 && data[key]['required']) {
                hasError = true
                data[key].error = 'Обязательно для заполнения'
            }
            row.push(String(data[key].value))
        }
        if (hasError) {
            onError(data)
            return
        }

        onClick()

        if (spreadsheet === undefined || String(spreadsheet).length == 0) {
            console.log("error 2")
            return
        }

        if (data === undefined) {
            console.log("error 3")
            return
        }
        const worker = new GoogleApi(spreadsheet)
        setLoading(true)
        const res = await worker.postRow(row)
        setLoading(false)
        return res
    }

    return (
        <div className="send-button__container">
            <div className="send-button">
                <button className="send-button" onClick={sendData}>Отправить</button>
            </div>
        </div>
        
    )
}

export default SendButton