import { useState, useEffect } from "react"
import styles from './Question.module.css'

export default function QuestionCell(props) {
  const controller = props.controller
  const index = props.index;
  const question = controller.getQuestions()[index]

  // Цвет кнопки закрытия
  const closeColor = (index) => {
    if (index % 2 == 0) {
      return 'rgb(255, 255, 255)'
    }
    return 'rgb(225, 130, 96)'
  }

  // Цвет фона
  const backgroundColor = (index) => {
    if (index % 2 == 0) {
      return 'rgb(225, 130, 96)'
    }
    return 'transparent'
  }

  // Вызов метода контроллера на нажатие вопроса
  const toggleQuestion = (question) => {
    controller.toggleQuestion(question);
  }

  // Обновление открытия/закрытия конкретного вопроса
  const updateQuestion = () => {
    const answerBlock = document.querySelector(`#answer${index}`)
    const container = document.querySelector(`#question__container${index}`)
    const content = document.querySelector(`#question-content${index}`)
    const button = document.querySelector(`#question-button${index}`)

    const containerHeight = container.getBoundingClientRect().height
    const questionHeight = content.getBoundingClientRect().height
    let margin = (containerHeight - questionHeight) / 2
    margin = margin / 1920 * window.innerWidth

    if (controller.isOpenQuestion(question)) {
      // Вопрос открыт
      answerBlock.style.display = 'unset'
      container.style.height = 'fit-content'
      
      content.style.margin = `${margin}px auto`
      button.style.transform = 'rotate(45deg)';
      // button.style.animation = 'buttonOpenAnimation 1s linear forward'
    } else if (window.innerWidth > 800) {
      // Вопрос закрыт
      answerBlock.style.display = 'none'
      button.style.transform = 'rotate(0deg)'
      container.style.height = 'calc(135 / 1920 * 100vw)'
      content.style.margin = 'unset'
    } else {
      answerBlock.style.display = 'none'
      button.style.transform = 'rotate(0deg)'
      container.style.height = 'calc(65 / 375 * 100vw)'
      content.style.margin = 'unset'
    }
  }

  useEffect(() => {
    // Добавление хэндлера на обновление вопроса
    // a.k. делегат 
    controller.updateHandlers.push(() => {
      updateQuestion()
    })
  }, [])

  return (
    <button className={`${styles.question__container}`} id={`question__container${index}`} onClick={el => {toggleQuestion(question)}} style={{backgroundColor: `${backgroundColor(index)}`}}>
      <div className={styles.question__content_container} id={`question-content${index}`}>
        <div className={styles.question__row}>
          <div className={styles.question} id={`question${index}`}>
            { question.question }
          </div>
          <div className={styles.open} id={`question-button${index}`} style={{color: `${closeColor(index)}`}}>+</div>
        </div>
        <div className={styles.answer} id={`answer${index}`}>
          { question.answer }
        </div>
      </div>
    </button>
  )
}