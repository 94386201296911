import styles from './Header.module.css'

export default function Header() {
  return (
    <div className={styles.header__container} id='main'>
      <h1 className={styles.name}>give</h1>
      <div className={styles.about}>
        <p className={styles.about_create}>создаем</p><br></br>
        <p className={styles.about_invitation}>электронные пригласительные на вашу свадьбу</p>
        <div className={styles.about_tilda}>
          <p>
            ~Сайт - пригласительное, которое все расскажет
            о приближающемся торжестве за вас
          </p>
          <p>
            Закажите сейчас и сэкономьте время и деньги,
            узнав предпочтения ваших гостей~
          </p>
        </div>
      </div>
    </div>
  );
}