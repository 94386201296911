import styles from './RoundedPinkContainer.module.css'

import branch1 from '../../assets/img/branch1.png'


export default function RoundedPinkContainer(props) {
  return (
    <div className={styles.roundedPink__container}>
      {props.children}
      {/* <Image src={branch1} className={styles.branch1}/> */}
    </div>
  );
}