import { QuestionModel } from "../Model/QuestionModel.ts"
import { IQuestionService } from "./QuestionServiceInterface"

export class QuestionService implements IQuestionService {
    constructor() {}

    fetchQuestions(): QuestionModel[] {
        // TODO: Написать реализацию
        let results: QuestionModel[] = []
        let json = {
            results: [
                {
                    id: 0, 
                    question: "Как быстро можно получить пригласительное?",
                    answer: `Пригласительное будет готово в течении 3 дней 
                            и может быть сразу же отправлено гостям. 
                            Вы пригласите всех близких за 5 минут, 
                            не выходя из дома. Нужен только мессенджер.`
                },
                {
                    id: 1,
                    question: "Куда будут приходить данные после заполнения анкеты?",
                    answer: `Данные будут приходить в excel-таблицу, которую мы 
                            Вам вышлем вместе с пригласительным`
                }, 
                {
                    id: 2,
                    question: "Что если свадьба только через полгода?",
                    answer: `Вы можете заказать пригласительное сейчас, а в дальнейшем, 
                            при необходимости, мы изменим данные бесплатно! \n
                            Важно! Распространяется только на текст и Ваши изображения.`
                },
                {
                    id: 3,
                    question: "Как происходит оплата?",
                    answer: `Оплата происходит только после выполнения 
                            пригласительного и внесения необходимых правок 
                            (если такие будут)`
                },
                {
                    id: 4,
                    question: "Смогут ли гости посмотреть пригласительное с компьютера или планшета?",
                    answer: `Конечно, гости смогут посмотреть пригласительное с любого устройства!`
                }, 
                {
                    id: 5,
                    question: "А если возникнет форс-мажорная ситуация?",
                    answer: `При заказе пригласительного, мы с Вами остаемся до конца! 
                    При любых изменениях мы изменим данные бесплатно) \n
                    Важно! Распространяется только на текст и Ваши изображения.`
                }
            ]
        }

        for(let i = 0; i < json['results'].length; i++) {
            const obj: object = json['results'][i]
            let question = new QuestionModel()
            question.parseFromJSON(obj)
            results.push(question)
        }

        return results
    }
}