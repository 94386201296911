import styles from './RoundedBlueContainer.module.css'

import branch1 from '../../assets/img/branch1.png'
import branch2 from '../../assets/img/branch2.png'
import branch3 from '../../assets/img/branch3.png'

export default function RoundedBlueContainer(props) {
  return (
    <div className={styles.roundedBlue__container}>
      {props.children}
      {/* <img src={branch1} className={styles.branch1}/> */}
      {/* <img src={branch2} className={styles.branch2}/> */}
      {/* <img src={branch3} className={styles.branch3}/> */}
    </div>
  );
}