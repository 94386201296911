import styles from './Item.module.css'
import { useState, useEffect } from "react";
import { NavbarViewController } from '../../navbar/NavbarViewController.ts';

export default function Item(props) {
  const photo = props.photo;
  const name = props.name;
  const price = props.price;
  const href = props.href;

  const [isMounted, setMounted] = useState(false)
  const [navController, _] = useState(NavbarViewController.shared)

  useEffect(() => {
    setMounted(true)
  }, [])

  const onClickOrder = (e) => {
    navController.scrollToOrder()
  }

  return (
    <div className={styles.item__container}>
      <img className={styles.item_img} src={photo} width='420' height='315'/>
      <div className={styles.item__info}>
        <p className={styles.item__info_text}>{name} - {price}</p>
        <div className={styles.item__info_btn}>
          <button className={styles.btn_demo}>
            <a href={href} target="_blank" className={styles.link}>демо</a>
          </button>
          <button className={styles.btn_order} onClick={onClickOrder}>заказать</button>
        </div>
      </div>
    </div>
  );
}