import { QuestionModel } from "../Model/QuestionModel"
import { IQuestionService } from "../Service/QuestionServiceInterface"

interface IQuestionController {
    toggleQuestion(question: QuestionModel)
    fetchQuestions()
    getQuestions(): QuestionModel[]
    isOpenQuestion(question: QuestionModel): boolean
}

export class QuestionsController implements IQuestionController {
    // Все вопросы
    private questions: QuestionModel[]
    // Открытый вопрос
    public openedQuestion?: QuestionModel
    // Сервис получения списка вопросов
    private service: IQuestionService
    // Методы обновления каждого вопроса
    // Каждая ячейка добавляет сюда свой обработчик.
    // Как только открытый вопрос обновится, 
    // дёргается метод каждой ячейки (смотри метод didUpdate).
    // Таким образом, можно отследить изменения выбранного вопроса
    // и изменить UI
    public updateHandlers: Function[] = []

    constructor(service: IQuestionService) {
        this.service = service
        this.fetchQuestions()
    }

    // Метод открытия/закрытия вопроса
    // Parameters:
    //  – question: вопрос, на который нажали
    toggleQuestion(question: QuestionModel) {
        if (this.openedQuestion == undefined) {
            this.openedQuestion = question
            this.didUpdate()
            return
        }

        if (this.openedQuestion!.id == question.id) {
            this.openedQuestion = undefined
            this.didUpdate()
            return
        }

        this.openedQuestion = question
        this.didUpdate()
    }

    // Метод, который дёргает функции обновления 
    // в каждой ячейке
    didUpdate() {
        for(let i = 0; i < this.updateHandlers.length; i++) {
            this.updateHandlers[i]()
        }
    }

    // Метод, проверяющий, открыт тот или иной вопрос
    // Parameters:
    //  – question: вопрос, который проверяется на открытость/закрытость
    isOpenQuestion(question: QuestionModel): boolean {
        if (this.openedQuestion == undefined) {
            return false
        }
        return this.openedQuestion!.id == question.id
    }

    // Метод получения всех вопросов из источника
    fetchQuestions() {
        this.questions = this.service.fetchQuestions()
    }

    // Метод получения всех вопросов из контроллера
    // Все вопросы помечены как private, чтобы не было
    // возможности изменять список извне (из UI, например).
    // Поэтому все полученные вопросы получаются через этот 
    // метод.
    getQuestions(): QuestionModel[] {
        return this.questions
    }
}