export class QuestionModel {
    id: number
    question: string = "";
    answer: string = "";

    constructor(id: number = 0, question: string = "", answer: string = "") {
        this.id = id
        this.question = question
        this.answer = answer
    }

    parseFromJSON(json: object) {
        // Предполагаемая модель:
        // {
        //   id: 0,
        //   question: "some question",
        //   answer: "some answer"
        // }
        this.id = json['id']
        this.question = json['question']
        this.answer = json['answer']
    }
}
