import styles from './Catalog.module.css'
import Item from './item/Item'

import ph1 from '../../assets/img/Desert.svg'
import ph2 from '../../assets/img/Black.svg'
import ph3 from '../../assets/img/Party.svg'
import ph4 from '../../assets/img/Peony.svg'
import ph5 from '../../assets/img/Gold.svg'
import ph6 from '../../assets/img/Emerald.svg'
import ph7 from '../../assets/img/Family.svg'

import phmob1 from '../../assets/img/Desert-mob.svg'
import phmob2 from '../../assets/img/Black-mob.svg'
import phmob3 from '../../assets/img/Party-mob.svg'
import phmob4 from '../../assets/img/Peony-mob.svg'
import phmob5 from '../../assets/img/Gold-mob.svg'
import phmob6 from '../../assets/img/Emerald-mob.svg'
import phmob7 from '../../assets/img/Family-mob.svg'

const makets = [
  [phmob1, 'Desert', '4500', 'https://svtoday.ru/victor_mariya'],
  [phmob2, 'Black', '4500', 'https://svtoday.ru/pavel_alina'],
  [phmob3, 'Party', '4500', 'https://svtoday.ru/sergey_marina'],
  [phmob4, 'Peony', '4500', 'https://svtoday.ru/pink_maket'],
  // [ph5, 'Gold', '2900', ' '],
  [phmob7, 'Family', '4000', 'https://svtoday.ru/white_maket'],
  [phmob6, 'Emerald', '4500', 'https://svtoday.ru/dmitry_elena'] 
]

export default function Catalog(props) {
  return (
    <div className={styles.catalog__container} id='catalog'>
      <h2 className={styles.catalog_title}>
      Более <span className={styles.catalog_red}>100</span> невест нашли свой стиль, найдете и вы
      </h2>
      <div className={`${styles.catalog__label} ${styles.catalog__label_new}`}>
        <span className={styles.catalog__label_new_text}>new</span>
      </div>
      <div className={`${styles.catalog__label} ${styles.catalog__label_hit}`}>
        <span className={styles.catalog__label_hit_text}>hit</span>
      </div>
      <div className={styles.catalog__grid}>
        {
          makets.map((el, index) => {
            return (<Item 
                      photo={el[0]}
                      name={el[1]}
                      price={el[2]}
                      href={el[3]}
                    />)
          })
        }
      </div>
      {/* <img src={ph1} className={styles.img} height={550} width={500}/> */}
    </div>
  );
}