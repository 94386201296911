import styles from './Footer.module.css'
import telegram from './assets/telegram.svg'
import whatsapp from './assets/whatsapp.svg'

export default function Footer() {
  return(
    <div className={styles.footer__container}>
      <p className={styles.text}>© 2023 - 2024 Разработка онлайн приглашений - GIVE</p>
      <a href="/" className={styles.link}>Политика конфиденциальности</a>
      <div className={styles.messengers_grid}>
        <a href="https://t.me/YG_force1" target="_blank"><img className={styles.grid_img} src={telegram} width='43' height='43'/></a>
        <a href="https://t.me/YG_force1" target="_blank" className={styles.grid_link}>Telegram</a>
        <a href="https://wa.me/79046526326" target="_blank"><img className={styles.grid_img} src={whatsapp} width='43' height='43'/></a>
        <a href="https://wa.me/79046526326" target="_blank" className={styles.grid_link}>WhatsApp</a>
      </div>
      <p className={`${styles.text} ${styles.last_text}`}>Не является публичной офертой</p>
      <p className={`${styles.text} ${styles.last_text}`}>
        Любое копирование и распространение цифрового контента без ведома 
        правообладателей преследуется по закону и отслеживается службой по антиплагиату.
      </p>
    </div>
  )
}