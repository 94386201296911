import {useState, useEffect} from 'react'
import styles from './Questions.module.css'
import QuestionCell from './QuestionCell/QuestionCell'
import { QuestionsController } from '../Controller/QuestionsController.ts'
import { QuestionService } from '../Service/QuestionService.ts'


export default function Questions() {
  const [controller, _] = useState(new QuestionsController(new QuestionService()))
  
  return (
    <div className={styles.questions__container}>
      <h2 className={styles.title}>Еще больше о нашем продукте</h2>
      <div className={styles.questions_grid}>
        {
          controller.getQuestions().map((el, index) => {
            return (
              <QuestionCell
                controller={controller}
                index={index}
              />
            )
          })
        }
      </div>
    </div>
  )
}