export default class GoogleApi {
    constructor(spreadsheetId = '', url = 'https://script.google.com/macros/s/AKfycbxNhJSq3sS8vlRFE-dXzyOV3CTE8t1UB4nqEPmJWsZ4VfVWyALIpLizLiOcpJZHJHPRjw/exec') {
        this.url = url
        this.spreadsheetId = spreadsheetId
    }

    async postRow(data, sheetName = 'Заказы', startRow = -1, startColumn = 1) {
        const formData = new FormData()
        formData.append("startRow", startRow)
        formData.append("startColumn", startColumn)
        formData.append("columnsCount", data.length)
        formData.append("spreadsheetId", this.spreadsheetId)
        formData.append("sheetName", sheetName)

        let str = `startRow=${startRow}&startColumn=${startColumn}&columnsCount=${data.length}&spreadsheetId=${this.spreadsheetId}&sheetName=${sheetName}`

        for(let i = 0; i < data.length; i++ ){
            formData.append(`data[${i}]`, data[i])
            str += `&data[${i}]=${String(data[i]).trim()}`
        }

        let res = await fetch(this.url + "?" + str, {
            method: "GET",
            mode: "no-cors"
        })
        return res
        
    }
}