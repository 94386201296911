import { NavBarItem } from "./Models/NavBarItem.ts"
import styles from "./Navbar.module.css"
// MARK: - NavbarViewController

export class NavbarViewController {
  // MARK: - Private properties
  private navBarLinks: NavBarItem[]
  private selectedItem: NavBarItem

  // MARK: - Public properties

  static shared: NavbarViewController = new NavbarViewController()

  // MARK: - Initialization

  private constructor() {}

  // MARK: - Public methods

  // Метод конфигурации навбара при монтировании вюьшки в иерархию DOM
  configureNavBar() {
    this.navBarLinks = this.configureBarLinks()
    for(let i = 0; i < this.navBarLinks.length; i++) {
      let item = this.navBarLinks[i]
      const link = document.querySelector(`#${item.tag}`)
      const pos = link?.getBoundingClientRect().y
      if (pos === undefined) continue
      item.yPosition = pos!
    }
    const pos = document.documentElement.scrollTop
    this.handleScroll(pos)
    this.setScrollListener()
  }

  // Метод получения всех объектов навбара
  getNavBarLinks(): NavBarItem[] {
    return this.navBarLinks
  }

  getStyleFor(item: NavBarItem): string {
    if (item == this.selectedItem) {
      return `${styles.link} ${styles.link_active}`
    } else {
      return `${styles.link}`
    }
  }

  scrollToOrder() {
    let orderItem = this.navBarLinks.filter(el => { return el.tag == "order"})
    if (orderItem.length == 0) { return }
    this.scrollTo(orderItem[0])
  }

  // Метод скролла до какого-либо пункта меню
  // Parameters:
  // - item: Пункт нав бара, до которого скроллить
  scrollTo(item: NavBarItem) {
    const defaultScrollStep = 20
    let currentPos = document.documentElement.scrollTop
    const destinationPos = item.yPosition
    let distance = destinationPos - currentPos
    let step = distance > 0 ? defaultScrollStep : -defaultScrollStep
    const isToBottom = distance > 0

    function animatableScroll() {
      document.documentElement.scrollTop += step
      currentPos = document.documentElement.scrollTop
      distance = destinationPos - currentPos

      /** 
       * Если расстояние между текущей позицией и точкой назначения
       * меньше начального шага, то шаг на каждой итерации уменьшается вдвое
      */
      if (Math.abs(distance) > defaultScrollStep) {
        step = distance > 0 ? defaultScrollStep : -defaultScrollStep
      } else {
        step =  distance / 2
      }

      if ((distance < 0 && isToBottom) || (distance > 0 && !isToBottom)) {
        clearInterval(interval)
        return
      }

      if (step > 0 && Math.abs(distance) < 2 || currentPos >= document.body.getBoundingClientRect().height - window.innerHeight) {
        clearInterval(interval)
        return
      }
      if (step < 0 && Math.abs(distance) < 2 || currentPos <= 0) {
        clearInterval(interval)
      }
    }

    const interval = setInterval(animatableScroll, 1)
  }

  // MARK: - Private methods

  // Метод создаёт и возвращает табы бара
  private configureBarLinks(): NavBarItem[] {
    if (window.innerWidth <= 800) {
      return [
        new NavBarItem("main", "Главная"),
        new NavBarItem("order", "Заявка")
      ]
    }

    return [
      new NavBarItem("main", "Главная"),
      new NavBarItem("catalog", "Каталог"),
      new NavBarItem("about", "О продукте"),
      new NavBarItem("order", "Заявка"),
      new NavBarItem("reviews", "Отзывы")
    ]
  }

  // Добавление слушателя скролла
  private setScrollListener() {
    document.addEventListener('scroll', () => {
      const pos = document.documentElement.scrollTop
      this.handleScroll(pos)
    })
  }

  // Обработка изменения текущего скрола
  // Parameters:
  // - yPos: Текущее положение скролла 
  private handleScroll(yPos: number) {
    for(let i = this.navBarLinks.length - 1; i >= 0; i--) {
      const itemPos = this.navBarLinks[i].yPosition
      
      if (itemPos <= yPos) {
        this.selectBarItem(this.navBarLinks[i])
        break
      }
    }
  }

  // Изменение выбранного элемента
  // Parameters: 
  // - item: Элемент: который необходимо подсветить
  private selectBarItem(item: NavBarItem) {
    if (item == this.selectedItem) return

    for(let i = 0; i < this.navBarLinks.length; i++) {
      document.querySelector(`#${this.navBarLinks[i].tag}-button`)?.classList.remove(styles.link_active)
    }

    document.querySelector(`#${item.tag}-button`)?.classList.add(styles.link_active)

    this.selectedItem = item
  }
}