import styles from './About.module.css'
import Questions from './Questions/View/Questions'
import AboutIvitation from './AboutInvitation/AboutInvitation'
import OrderDetails from './OrderDetails/OrderDetails'

export default function About() {
  return (
    <div className={styles.about__container} id='about'>
      <Questions />
      <AboutIvitation />
      <OrderDetails />
    </div>
  )
}