export class NavBarItem {
  tag: string
  title: string
  yPosition: number

  constructor(tag: string, title: string) {
    this.tag = tag
    this.title = title
    this.yPosition = 0
  }
}